import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './i18next'
import { UserStateProvider } from './stores/userStore'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const theme = createTheme({
  palette: {
    primary: {
      main: '#336666',
    },
    secondary: {
      main: '#98b3b3',
    },
  },
})

if (process.env.NODE_ENV === 'production') {
  let environment
  try {
    environment = process.env.REACT_APP_API_URL.match(/\/\/(\w+)/)[1]
    if (environment === 'api') {
      environment = 'production'
    }
  } catch (e) {}
  const release = process.env.REACT_APP_SENTRY_RELEASE
  Sentry.init({
    dsn:
      'https://c01417e0a0f74a1394f5736442d7bfd6@o432542.ingest.sentry.io/5514809',
    environment,
    release,
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: environment === 'production' ? 0.1 : 0.5,
  })
}

ReactDOM.render(
  <Suspense
    fallback={
      <div className="app-loading">
        <CircularProgress />
      </div>
    }
  >
    <ThemeProvider theme={theme}>
      <UserStateProvider>
        <App />
      </UserStateProvider>
    </ThemeProvider>
  </Suspense>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
