import React, { useContext, Fragment, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.scss'
import './styles/tailwind'
import { store } from './stores/userStore'
import { PrivateRoute } from './components/global/PrivateRoute/PrivateRoute'
import { PublicRoute } from './components/global/PublicRoute/PublicRoute'
import SkipLink from './components/global/SkipLink/SkipLink'
import { useTranslation } from 'react-i18next'
import TermsOfUse from './pages/TermsOfUse/TermsOfUse'
import { QueryParamProvider } from 'use-query-params'
import InjectAxiosInterceptors from './interceptors/InjectAxiosInterceptors'

const Login = lazy(() => import('./pages/Auth/Login'))
const TwoFactor = lazy(() => import('./pages/Auth/TwoFactor'))
const Home = lazy(() => import('./pages/Home/Home'))
const Menu = lazy(() => import('./components/Menu/Menu'))
const AddAdminUsers = lazy(() =>
  import('./pages/ManageAdminUsers/AddAdminUsers')
)
const EditAdminUsers = lazy(() =>
  import('./pages/ManageAdminUsers/EditAdminUsers')
)
const ManageAdminUsers = lazy(() =>
  import('./pages/ManageAdminUsers/ManageAdminUsers')
)
const ManageAppUsers = lazy(() =>
  import('./pages/ManageAppUsers/ManageAppUsers')
)
const AddAppUsers = lazy(() => import('./pages/ManageAppUsers/AddAppUsers'))
const EditAppUsersWrapper = lazy(() =>
  import('./pages/ManageAppUsers/EditAppUsersWrapper')
)
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'))
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'))
const Profile = lazy(() => import('./pages/Profile/Profile'))
const TargetAudiences = lazy(() =>
  import('./pages/TargetAudiences/TargetAudiences')
)
const TargetAudiencesAdd = lazy(() =>
  import('./pages/TargetAudiences/TargetAudiencesAdd')
)
const NoMatch = lazy(() => import('./pages/NoMatch/NoMatch'))
const AddAssessment = lazy(() => import('./pages/Assessments/AddAssessment'))
const EditAssessmentWrapper = lazy(() =>
  import('./pages/Assessments/EditAssessmentWrapper')
)
const PendingAssessments = lazy(() =>
  import('./pages/Assessments/PendingAssessments')
)
const ActiveAssessments = lazy(() =>
  import('./pages/Assessments/ActiveAssessments')
)
const FinishedAssessments = lazy(() =>
  import('./pages/Assessments/FinishedAssessments')
)
const ChatConversations = lazy(() => 
  import('./pages/Chat/ChatConversations')
)
const Chat = lazy(() => 
  import('./pages/Chat/Chat')
)
const NewChat = lazy(() => 
  import('./pages/Chat/StartChat/NewChat')
)
const ArchivedCon = lazy(() => 
  import('./pages/Chat/Archive/ArchivedCon')
)
const TargetAudiencesEdit = lazy(() => 
  import('./pages/TargetAudiences/TargetAudiencesEdit')
)

function App() {
  const userStore = useContext(store)
  const { t } = useTranslation()
  window.addEventListener(
    'touchstart',
    function onFirstTouch() {
      document.body.classList.add('user-has-touch')

      // we only need to know once that a human touched the screen, so we can stop listening now
      window.removeEventListener('touchstart', onFirstTouch, false)
    },
    false
  )

  return (
    <Router>
      <InjectAxiosInterceptors />
      <QueryParamProvider ReactRouterRoute={Route}>
        <main className="App">
          <SkipLink
            href="#content-wrapper"
            content={t('ACCESSIBILITY_SKIP_TO_CONTENT')}
          />
          {userStore.state.isAuthenticated ? <Menu /> : <Fragment />}
          <Switch>
            <PublicRoute
              restricted={false}
              component={TermsOfUse}
              path="/terms-of-use"
              exact
            />

            <PublicRoute restricted={true} component={Login} path="/" exact />
            <PublicRoute
              restricted={true}
              component={TwoFactor}
              path="/2fa"
              exact
            />
            <PublicRoute
              path="/forgot-password"
              exact
              component={ForgotPassword}
              restricted={true}
            />
            <PublicRoute
              path="/reset-password/:token"
              exact
              component={ResetPassword}
              restricted={true}
            />

            <PrivateRoute path="/home" exact component={Home} />
            <PrivateRoute
              path="/pending-assessments"
              exact
              component={PendingAssessments}
            />
            <PrivateRoute
              path="/active-assessments"
              exact
              component={ActiveAssessments}
            />
            <PrivateRoute
              path="/finished-assessments"
              exact
              component={FinishedAssessments}
            />
            <PrivateRoute
              path="/assessments/add"
              exact
              component={AddAssessment}
            />
            <PrivateRoute
              path="/assessments/edit/:id"
              exact
              component={EditAssessmentWrapper}
            />
            <PrivateRoute
              path="/assessments/view/:id"
              exact
              component={EditAssessmentWrapper}
            />
            <PrivateRoute
              path="/manage-users"
              exact
              component={ManageAdminUsers}
            />
            <PrivateRoute
              path="/manage-app-users"
              exact
              component={ManageAppUsers}
            />
            <PrivateRoute
              path="/manage-app-users/add"
              exact
              component={AddAppUsers}
            />
            <PrivateRoute
              path="/manage-app-users/edit/:id"
              exact
              component={EditAppUsersWrapper}
            />
            <PrivateRoute
              path="/manage-users/add"
              exact
              component={AddAdminUsers}
            />
            <PrivateRoute
              path="/manage-users/edit/:id"
              exact
              component={EditAdminUsers}
            />
            <PrivateRoute
              path="/target-audiences"
              exact
              component={TargetAudiences}
            />
            <PrivateRoute
              path="/target-audiences/add"
              exact
              component={TargetAudiencesAdd}
            />
            <PrivateRoute
              path="/target-audiences/edit/:id"
              exact
              component={TargetAudiencesEdit}
            />
            <PrivateRoute
              path="/chat-conversations"
              exact
              component={ChatConversations}
            />
            <PrivateRoute
              path="/chat-conversations/conversation/user/:id"
              exact
              component={Chat}
            />
            <PrivateRoute
              path="/chat-conversations/conversation/new/user/:id"
              exact
              component={NewChat}
            />
            <PrivateRoute
              path="/chat-conversations/conversation/:id"
              exact
              component={Chat}
            />
            <PrivateRoute
              path="/chat-conversations/archive"
              exact
              component={ArchivedCon}
            />
            <PrivateRoute path="/profile" exact component={Profile} />
            <PrivateRoute path="*" component={NoMatch} />
          </Switch>
        </main>
      </QueryParamProvider>
    </Router>
  )
}

export default App
