import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './LanguageSelector.scss'
import { useForm, Controller } from 'react-hook-form'
import { Select, InputLabel, MenuItem } from '@material-ui/core'
import AppLanguages from '../../utilities/AppLanguages'
import { LanguageRounded, TranslateRounded } from '@material-ui/icons'

const LanguageSelector = props => {
  const {
    onLanguageChange,
    language: languageProp,
    languages: languagesProp,
  } = props
  const { t, i18n } = useTranslation()
  i18n.loadLanguages(AppLanguages.map(l => l.ISO639_1))
  const { control, watch } = useForm()

  const languages = AppLanguages.filter(l =>
    languagesProp ? languagesProp.includes(l.ISO639_1) : true
  )

  const language = AppLanguages.some(l => l.ISO639_1 === languageProp)
    ? languageProp
    : 'en'
  const watchLanguage = watch('language', language)

  useEffect(() => {
    onLanguageChange(watchLanguage)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchLanguage])

  return (
    <form className={'language-selector'}>
      <InputLabel
        htmlFor="language"
        className={'mr-4'}
        aria-label={t('LANGUAGE', { lng: watchLanguage })}
      >
        <LanguageRounded fontSize="small" />
        <TranslateRounded fontSize="small" />
      </InputLabel>
      <Controller
        as={
          <Select
            value={watchLanguage}
            labelId="language"
            label={t('LANGUAGE', { lng: watchLanguage })}
            name="language"
          >
            {languages.map(lang => (
              <MenuItem key={lang.id} value={lang.ISO639_1}>
                {t(lang.name, { lng: watchLanguage }).startsWith(
                  'APP_LANGUAGE_'
                )
                  ? lang.localName
                  : `${lang.localName} (${t(lang.name, {
                      lng: watchLanguage,
                    })})`}
              </MenuItem>
            ))}
          </Select>
        }
        control={control}
        name="language"
        defaultValue={watchLanguage}
      />
    </form>
  )
}

export default LanguageSelector
