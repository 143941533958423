import Header from '../../components/Header/Header'
import React, { Fragment, useContext, useState } from 'react'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'
import { useTranslation } from 'react-i18next'
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector'
import useLoadResource from '../../hooks/useLoadResource'
import './TermsOfUse.scss'
import { store } from '../../stores/userStore'

const TermsOfUse = () => {
  const { t } = useTranslation()

  const [query, setQuery] = useQueryParams({
    language: withDefault(StringParam, 'en'),
  })
  const { language: languageQuery } = query
  const [language, setLanguage] = useState(languageQuery)
  const [termsOfUseTitle, setTermsOfUseTitle] = useState('')
  const [termsOfUse, setTermsOfUse] = useState([])

  const termsUrl = `useragreements?language=${language}`
  useLoadResource(termsUrl, {}, data => {
    const translations = data.data?.translations
    if (translations && translations[0]) {
      const content = translations[0].text?.split('<br />')
      setTermsOfUseTitle(translations[0].title)
      setTermsOfUse(content)
    } else {
      setTermsOfUseTitle(t('RESOURCE_NOT_FOUND_404'))
      setTermsOfUse([])
    }
  })

  const userStore = useContext(store)

  function onLanguageChange(language) {
    setLanguage(language)
    setQuery({
      language: language,
    })
  }

  return (
    <Fragment>
      {userStore.state.isAuthenticated ? null : <Header />}
      <div
        className={`content-wrapper ${
          userStore.state.isAuthenticated ? '' : 'no-menu'
        }`}
      >
        <div className="content-container">
          <LanguageSelector
            onLanguageChange={onLanguageChange}
            language={language}
          />
          <h1 className="terms-title">Privacy policy and Terms of Use for PicPecc application</h1>
          <!--<h1 className="terms-title">{termsOfUseTitle}</h1>-->
          <div className="terms-content">
            {termsOfUse?.map((p, i) => (
              <Fragment key={i}>
                <p>{p}</p>
                <br />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default TermsOfUse
