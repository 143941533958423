import axios from 'axios'
import { API_URL } from '../utilities/HandleUrl'
import jwt_decode from 'jwt-decode'

const Interceptor = (userStore, history) => {
  const { userDispatch } = userStore

  let isRefreshing = false
  let requestsToRefresh = []

  const tokenExpired = token => {
    return token && Date.now() >= jwt_decode(token).exp * 1000
  }

  const storeToken = token => {
    localStorage.setItem('userToken', token)
  }

  axios.interceptors.response.use(null, error => {
    const { response, config } = error

    const refreshToken = localStorage.getItem('refreshToken')
    const accessToken = localStorage.getItem('userToken')
    const refreshTokenExpired = tokenExpired(refreshToken)
    const accessTokenExpired = tokenExpired(accessToken)

    // If user is not auth
    if (
      response.status !== 403 &&
      response.status !== 500 &&
      (response.status === 401 || response.status === 422)
    ) {
      return Promise.reject(error)
    }

    // If refreshToken has expired or doesn't exist and
    // accessToken has expired or doesn't exist
    // log out the user
    if (
      (refreshTokenExpired || !refreshToken) &&
      (accessTokenExpired || !accessToken)
    ) {
      userDispatch({ type: 'authLogout' })
      history.push('/')
      return Promise.reject(error)
    }

    // User is auth, accessToken have expired, try to refresh
    // And send all failed requests again with new access token
    if (!isRefreshing && (accessTokenExpired || !accessToken)) {
      isRefreshing = true

      const axiosConfig = {
        headers: { Authorization: `Bearer ${refreshToken}` },
      }

      // Send request to refresh token
      axios
        .post(`${API_URL}/refresh`, null, axiosConfig)
        .then(response => {
          const accessToken = response.data.access_token
          storeToken(accessToken)
          requestsToRefresh.forEach(cb => cb(response.data.access_token))
        })
        .catch(() => {
          console.log('Error refreshing access token - Error: ', error)
          requestsToRefresh.forEach(cb => cb(null))

          // Log out user if token refresh didn't work
          userDispatch({ type: 'authLogout' })
          history.push('/')
          return Promise.reject(error)
        })
        .finally(() => {
          requestsToRefresh = []
          isRefreshing = false
        })

      // Send all failed requests again with new access token
      return new Promise((resolve, reject) => {
        requestsToRefresh.push(token => {
          if (token) {
            config.headers.Authorization = `Bearer ${token}`
            resolve(axios(config))
          }
          reject(error)
        })
      })
    }
    return Promise.reject(error)
  })

  axios.interceptors.request.use(
    config => {
      const accessToken = localStorage.getItem('userToken')
      const refreshToken = localStorage.getItem('refreshToken')
      const accessTokenExpired = tokenExpired(accessToken)
      const refreshTokenExpired = tokenExpired(refreshToken)

      const request = config.url.split('/').pop()

      if (accessToken && !accessTokenExpired) {
        config.headers.Authorization = `Bearer ${accessToken}`
      } else if (
        request === 'refresh' &&
        refreshToken &&
        !refreshTokenExpired &&
        !isRefreshing
      ) {
        config.headers.Authorization = `Bearer ${refreshToken}`
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
}

export default Interceptor
