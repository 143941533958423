import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { store } from '../../../stores/userStore'

export const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const userStore = useContext(store)
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={props =>
        userStore.state.isAuthenticated && restricted ? (
          <Redirect to="/home" />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}
