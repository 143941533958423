import React from 'react'
import './Header.scss'
import logo from '../../images/logo.png'

const Header = () => {
  return (
    <div className="header px-8">
      <img src={logo} alt="Picpecc Administration" />
    </div>
  )
}

export default Header
