import { useEffect, useContext } from 'react'
import { store } from '../stores/userStore'
import { useHistory } from 'react-router-dom'
import Interceptor from './Interceptor'

const InjectAxiosInterceptors = () => {
  const history = useHistory()
  const userStore = useContext(store)

  useEffect(() => {
    Interceptor(userStore, history)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]) // Adding userStore to the dependencies will cause bugs, do NOT add userStore

  // not rendering anything
  return null
}

export default InjectAxiosInterceptors
