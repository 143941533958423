import React from 'react'
import './SkipLink.scss'

const SkipLink = props => {
  const { href, content } = props
  return (
    <a className="skip-link" href={href}>
      {content}
    </a>
  )
}

export default SkipLink
