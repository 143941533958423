import React, { createContext, useReducer } from 'react'

const initialState = {
  user: localStorage.getItem('user'),
  isAuthenticating: false,
  isAuthenticated: localStorage.getItem('userToken') !== null,
  needs2FA: false,
  authError: null,
}

const store = createContext(initialState)
const { Provider } = store

const UserStateProvider = ({ children }) => {
  const [state, userDispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'authBegin':
        return {
          ...state,
          isAuthenticating: true,
        }

      case 'authNeeds2FA':
        return {
          ...state,
          user: action.user,
          needs2FA: true,
          isAuthenticating: false,
        }

      case 'authSuccess':
        localStorage.setItem('user', JSON.stringify(action.user))
        return {
          ...state,
          user: action.user,
          isAuthenticating: false,
          isAuthenticated: true,
          needs2FA: false,
          authError: null,
        }

      case 'authFailure':
        return {
          ...state,
          user: null,
          isAuthenticating: false,
          isAuthenticated: false,
          needs2FA: false,
          authError: action.error,
        }

      case 'authLogout':
        localStorage.removeItem('user')
        localStorage.removeItem('userToken')
        localStorage.removeItem('refreshToken')
        return {
          user: null,
          isAuthenticating: false,
          isAuthenticated: false,
          needs2FA: false,
          authError: null,
        }

      default:
        throw new Error()
    }
  }, initialState)

  return <Provider value={{ state, userDispatch }}>{children}</Provider>
}

export { store, UserStateProvider }
