import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { store } from '../../../stores/userStore'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const userStore = useContext(store)
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to the login page
    <Route
      {...rest}
      render={props =>
        userStore.state.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  )
}
