const AppLanguages = [
  {
    id: 1,
    ISO639_1: 'en',
    tag: 'english',
    name: 'APP_LANGUAGE_ENGLISH',
    localName: 'English',
  },
  {
    id: 2,
    ISO639_1: 'sv',
    tag: 'swedish',
    name: 'APP_LANGUAGE_SWEDISH',
    localName: 'Svenska',
  },
  {
    id: 3,
    ISO639_1: 'da',
    tag: 'danish',
    name: 'APP_LANGUAGE_DANISH',
    localName: 'Dansk',
  },
  {
    id: 4,
    ISO639_1: 'is',
    tag: 'icelandic',
    name: 'APP_LANGUAGE_ICELANDIC',
    localName: 'Íslenska',
  },
  {
    id: 5,
    ISO639_1: 'af',
    tag: 'afrikaans',
    name: 'APP_LANGUAGE_AFRIKAANS',
    localName: 'Afrikaans',
  },
  {
    id: 6,
    ISO639_1: 'st',
    tag: 'sesotho',
    name: 'APP_LANGUAGE_SESOTHO',
    localName: 'Sesotho',
  },
  {
    id: 7,
    ISO639_1: 'zu',
    tag: 'isizulu',
    name: 'APP_LANGUAGE_ISIZULU',
    localName: 'Isizulu',
  },
  {
    id: 8,
    ISO639_1: 'tn',
    tag: 'setswana',
    name: 'APP_LANGUAGE_SETSWANA',
    localName: 'Setswana',
  },
  {
    id: 9,
    ISO639_1: 'nso',
    tag: 'sepedi',
    name: 'APP_LANGUAGE_SEPEDI',
    localName: 'Sepedi',
  },
  {
    id: 10,
    ISO639_1: 've',
    tag: 'venda',
    name: 'APP_LANGUAGE_VENDA',
    localName: 'Venda',
  },
  {
    id: 11,
    ISO639_1: 'ts',
    tag: 'tsonga',
    name: 'APP_LANGUAGE_TSONGA',
    localName: 'Tsonga',
  },
  {
    id: 12,
    ISO639_1: 'de',
    tag: 'german',
    name: 'APP_LANGUAGE_GERMAN',
    localName: 'Deutsch',
  },
  {
    id: 13,
    ISO639_1: 'zah',
    tag: 'eng_zah',
    name: 'APP_LANGUAGE_ENGLISH_ZAH',
    localName: 'English ZAH',
  },
  {
    id: 14,
    ISO639_1: 'gbg',
    tag: 'eng_gbg',
    name: 'APP_LANGUAGE_ENGLISH_GBG',
    localName: 'English GBG',
  },
]

export default AppLanguages
